<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <div v-if="!loading">
                    <zw-table ref="table"
                              tableName="salesAttachments"
                              columnsPrefix="salse.salesAttachments.column."
                              :items="myProvider"
                              :fields="fields"
                              :params="params"
                              :filter="filter"
                              :selected="selected"
                              :actions-list="getActions()"
                    >

                        <template #cell(name)="row">
                            <a @click="downloadAttachment(row.item.id)"
                               href="#">{{ row.item.name.replace(/^.*[\\\/]/, '') }}</a>
                        </template>
                        <template #cell(actions)="row">
                            <b-dropdown class="mb-1"
                                        left
                                        variant="primary"
                                        size="sm"
                            >
                                <template #button-content>
                                    {{ $t('common.label.actions') }}
                                </template>
                                <b-dropdown-item v-if="row.item._delete"
                                                 @click="deleteAttachment(row.item.id)">
                                    <font-awesome-icon class="mr-2" icon="trash"/>
                                    {{ $t('common.title.delete') }}
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </zw-table>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'SalesAttachments',
    mixins: [commonTable, commonSave],
    data() {
        return {
            loading: true,
            saveFilters: false
        }
    },
    methods: {
        ...mapGetters('Sales', ['getSalesAttachments']),
        shown() {
            this.loading = false
            this.$root.afterSettingsUpdate()
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Sales/fetchSalesAttachments', 'getSalesAttachments')
        },
        ctxAdditionalParams() {
            return {
                'type': 'offering_attachment',
                'parent_id': 'null',
            }
        },
        downloadAttachment(id) {
            this.$root.downloadDocument(id, 'media')
        },
        deleteAttachment(id) {
            Promise.all([this.$store.dispatch('Sales/deleteAttachment', id)]).then(() => {
                this.shown()
            })
        },
        getActions() {
            let actions = [
                {
                    title: this.$t('common.button.create'),
                    icon: 'plus',
                    click: () => {
                        this.$root.$children[0].openModal('sales-attachment-modal', {id: 0}, this.refreshTable, {})
                    }
                }
            ]
            return actions
        }
    },
    mounted() {
        this.shown()
    }
}
</script>